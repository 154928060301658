<template>
  <div class="mb-32">
    <section
      class="
        pt-8
        h-80
        lg:h-screen
        bg-cover
        object-cover
        bg-center
        md:bg-fixed
        background_image
        relative
      "
    >
      <div class="absolute inset-0 h-full w-full opacity-75 bg-black"></div>

      <div
        class="
          text-white
          absolute
          inset-0
          flex flex-col
          justify-center
          items-center
          w-full
        "
      >
        <div class="container mx-auto px-6 xl:px-12">
          <h1
            class="
              text-6xl
              md:text-9xl
              lg:text-10xl
              xl:text-12xl
              font-bold
              text-center
            "
          >
            {{ $t("Covid-19.title") }}
          </h1>
          <div
            class="
              -mt-6
              md:-mt-12
              xl:-mt-24
              text-center
              md:text-left
              max-w-xs
              lg:max-w-md
              ml-auto
            "
          >
            <p class="text-xl xl:text-3xl">{{ $t("Covid-19.subtitle") }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="container mx-auto px-6 xl:px-12 mt-20 md:mt-32">
      <p class="text-xl md:text-2xl">
        {{ $t("Covid-19.section1") }}
      </p>

      <p class="text-xl md:text-2xl mt-2">
        {{ $t("Covid-19.section2") }}
      </p>

      <p class="text-xl md:text-2xl mt-6">
        {{ $t("Covid-19.section3") }}
      </p>
    </section>

    <section
      class="
        container
        mx-auto
        px-6
        xl:px-12
        mt-20
        md:mt-32
        lg:grid-cols-3
        grid
        gap-16
        xl:gap-32
      "
    >
      <div class="text-center">
        <p class="text-3xl md:text-5xl font-bold">
          {{ $t("Covid-19.section4") }}
        </p>
        <svg
          class="mx-auto mt-8 md:mt-16"
          version="1.1"
           width="243.707"
          height="243.707"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 64 80"
          style="enable-background: new 0 0 64 80"
          xml:space="preserve"
        >
          <path
  d="M56.2,24.7L55,24.3V22h1.5c1.3,0,2.5-0.9,2.9-2.2l1.4-4.8c0.2-0.7,0.1-1.5-0.2-2.2L59,9.8V8.5C59,3.8,55.2,0,50.5,0h-0.9
	C44.8,0,41,3.8,41,8.5c0,2.9,1.5,5.6,4,7.1v8.8L43.8,25c-1.7,0.9-2.8,2.6-2.8,4.5v1.7l-4-1v-2.9l1.9-2.3c0,0,0,0,0,0c0,0,0,0,0,0
	c0.7-0.9,1.1-2,1.1-3.1V16c0-4.4-3.6-8-8-8s-8,3.6-8,8v5.9c0,1.1,0.4,2.3,1.1,3.1l1.9,2.3v2.9l-4,1v-1.7c0-1.9-1.1-3.6-2.8-4.5
  L19,24.4v-8.8c2.5-1.5,4-4.1,4-7.1C23,3.8,19.2,0,14.5,0h-0.9C8.8,0,5,3.8,5,8.5v1.2l-1.5,3.1C3.1,13.5,3,14.3,3.3,15l1.4,4.8
	C5,21.1,6.2,22,7.5,22H9v2.3l-1.2,0.4c-2.9,1-4.8,3.6-4.8,6.6V49v14c0,0.6,0.4,1,1,1h12h32h12c0.6,0,1-0.4,1-1V49V31.3
	C61,28.3,59.1,25.6,56.2,24.7z M47,24v-2h5h1v2H47z M58.8,14.5l-1.4,4.8c-0.1,0.4-0.5,0.7-1,0.7H54h-1v-8h4.9l0.9,1.8
	C58.9,14,58.9,14.2,58.8,14.5z M49.5,2h0.9C54.1,2,57,4.9,57,8.5V10h-5h-8.8C43.1,9.5,43,9,43,8.5C43,4.9,45.9,2,49.5,2z M44.1,12
	H51v8h-4v-5c0-0.4-0.2-0.7-0.6-0.9C45.5,13.6,44.7,12.9,44.1,12z M43,29.5c0-1.1,0.6-2.2,1.7-2.7l1.6-0.8h7.6l1.7,0.6
	c2,0.7,3.4,2.6,3.4,4.7V48H49V37.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0v-6c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.1c-0.5-0.4-1.1-0.7-1.8-0.9
	L43,31.7V29.5z M35,30.5c-1.7,1.5-4.3,1.5-6,0v-2.9c0.8,0.2,1.5,0.4,2.4,0.4h1.3c0.8,0,1.6-0.1,2.4-0.4V30.5z M38,21.9
	c0,0.7-0.2,1.4-0.7,1.9l0,0c-1.1,1.4-2.9,2.2-4.7,2.2h-1.3c-1.8,0-3.5-0.8-4.7-2.2c-0.4-0.5-0.7-1.2-0.7-1.9v-0.1l5.7-1.6
	c0.2-0.1,0.4-0.1,0.5,0l5.7,1.6V21.9z M32,10c3.3,0,6,2.7,6,6v3.7l-5.2-1.5c-0.5-0.2-1.1-0.2-1.6,0L26,19.7V16
	C26,12.7,28.7,10,32,10z M11,24v-2h1h5v2H11z M17.6,14.1C17.2,14.3,17,14.6,17,15v5h-4v-8h6.9C19.3,12.9,18.5,13.6,17.6,14.1z
	M7,8.5C7,4.9,9.9,2,13.5,2h0.9C18.1,2,21,4.9,21,8.5c0,0.5-0.1,1-0.2,1.5H12H7V8.5z M7.5,20c-0.4,0-0.8-0.3-1-0.7l-1.4-4.8
	c-0.1-0.2,0-0.5,0.1-0.7L6.1,12H11v8h-1H7.5z M8.4,26.6l1.7-0.6h7.6l1.6,0.8c1,0.5,1.7,1.5,1.7,2.7v2.2l-2.2,0.6
	c-0.7,0.2-1.3,0.5-1.8,0.9V31c0-0.6-0.4-1-1-1s-1,0.4-1,1v6c0,0,0,0,0,0c0,0,0,0.1,0,0.1V48H5V31.3C5,29.2,6.4,27.3,8.4,26.6z M5,50
	h10v12H5V50z M17,37.1c0-1.4,0.9-2.6,2.3-2.9l8.5-2.1c1.2,1,2.8,1.6,4.3,1.6c1.5,0,3-0.5,4.3-1.6l8.5,2.1c1.3,0.3,2.3,1.5,2.3,2.9
	V62h-4V43c0-0.6-0.4-1-1-1s-1,0.4-1,1v19H23V43c0-0.6-0.4-1-1-1s-1,0.4-1,1v19h-4V37.1z M59,62H49V50h10V62z"
          />
        </svg>

        <p class="mt-8 md:mt-16 text-xl md:text-2xl">
          {{ $t("Covid-19.section5") }}
        </p>
      </div>

      <div class="text-center">
        <p class="text-3xl md:text-5xl font-bold">
          {{ $t("Covid-19.section6") }}
        </p>
        <svg
          class="mx-auto mt-8 md:mt-16"
          width="243.707"
          height="243.707"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 640"
          style="enable-background: new 0 0 512 640"
          xml:space="preserve"
        >
          <path
            d="M454.5,492.6c1.5-2.1,1.9-4.8,1-7.3L411.2,360h4.8v-72.9c0-17.1-13.9-31-31.1-31.1h-33.9c-17.1,0-31,13.9-31.1,31.1V304h-56
	v-64h76.3c8.8,15.3,28.4,20.6,43.7,11.7c15.3-8.8,20.6-28.4,11.7-43.7c-8.6-14.9-27.5-20.4-42.7-12.2l-33.4-94.4
	c-1.1-3.2-4.2-5.3-7.5-5.3h-84.5c-5.8-9.9-16.4-16-27.9-16h-31.2c-17.9,0-32.4,14.5-32.4,32.4V184h8v54.2L56.5,485.3
	c-1.5,4.2,0.7,8.7,4.9,10.2c0.9,0.3,1.8,0.5,2.7,0.5h384C450.6,496,453,494.7,454.5,492.6z M368,208c8.8,0,16,7.2,16,16
	s-7.2,16-16,16s-16-7.2-16-16C352,215.2,359.2,208,368,208z M264,112h42.3l5.7,16h-48V112z M264,144h53.7l5.7,16H264V144z M264,176
	h65l11.3,32c-2.8,4.9-4.3,10.4-4.3,16h-72V176z M336,287.1c0-8.3,6.7-15.1,15.1-15.1h33.9c8.3,0,15.1,6.7,15.1,15.1V360h-8v80
	c0,4.4-3.6,8-8,8s-8-3.6-8-8v-80h-16v80c0,4.4-3.6,8-8,8s-8-3.6-8-8v-80h-8V287.1z M264,320h56v40h8v32h-64V320z M152,112.4
	c0-9.1,7.3-16.4,16.4-16.4h31.2c9.1,0,16.4,7.3,16.4,16.4V184h-8v80c0,4.4-3.6,8-8,8s-8-3.6-8-8v-80h-16v80c0,4.4-3.6,8-8,8
	s-8-3.6-8-8v-80h-8V112.4z M248,480H75.3l25.5-72H248V480z M248,392H106.5l25.5-72h116V392z M248,304H137.7l9.8-27.6
	c6.8,11.3,21.5,15,32.9,8.2c1.3-0.8,2.5-1.7,3.7-2.7c9.9,8.8,25,8,33.9-1.8c4-4.4,6.1-10.1,6.1-16v-24h24V304z M248,224h-24v-40h8
	v-8h16V224z M248,160h-16v-16h16V160z M248,128h-16v-15.6c0-0.1,0-0.3,0-0.4h16V128z M264,480v-72h64v32c0,13.2,10.7,24,24,24
	c5.9,0,11.6-2.2,16-6.1c9.9,8.8,25,8,33.9-1.8c4-4.4,6.1-10.1,6.1-16v-41l28.7,81H264z"
          />
          <path
            d="M216,48c0-17.7-14.3-32-32-32s-32,14.3-32,32s14.3,32,32,32C201.7,80,216,65.7,216,48z M184,64c-8.8,0-16-7.2-16-16
	s7.2-16,16-16s16,7.2,16,16C200,56.8,192.8,64,184,64z"
          />
        </svg>
        <p class="mt-8 md:mt-16 text-xl md:text-2xl">
          {{ $t("Covid-19.section7") }}
        </p>
      </div>

      <div class="text-center">
        <p class="text-3xl md:text-5xl font-bold">
          {{ $t("Covid-19.section8") }}
        </p>
        <svg
          class="mx-auto mt-8 md:mt-16"
          width="243.707"
          height="243.707"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 128 160"
          style="enable-background: new 0 0 128 160"
          xml:space="preserve"
        >
          <g>
            <path
              d="M109,4v12c0,5-4,9-9,9H50c-5,0-9-4-9-9V4c0-1.7-1.3-3-3-3s-3,1.3-3,3v12c0,8.3,6.7,15,15,15h14v11c0,1.7,1.3,3,3,3h16
		c1.7,0,3-1.3,3-3V31h14c8.3,0,15-6.7,15-15V4c0-1.7-1.3-3-3-3S109,2.3,109,4z M80,39H70v-8h10V39z"
            />
            <path
              d="M78,67V57c0-1.7-1.3-3-3-3s-3,1.3-3,3v10c0,1.7,1.3,3,3,3S78,68.7,78,67z"
            />
            <path
              d="M125.9,95.2c1.1-2.8,1-5.8-0.3-8.6c-2.5-5.3-9-7.8-14.6-5.4l-1.8,0.8l-0.5-1.1c-1.1-2.7-3.3-4.8-6-5.9
		c-2.7-1-5.7-1-8.4,0.2l-19.2,8.4c-0.2-0.1-0.4-0.2-0.6-0.3L48.6,71.7c-11-4.9-23.8-3.4-33.4,3.9c-1.3,1-1.6,2.9-0.6,4.2
		c1,1.3,2.9,1.6,4.2,0.6c7.9-6,18.3-7.2,27.4-3.2L72,88.7c0.9,0.4,1.6,1,2.2,1.9c0.9,1.4,1,3.2,0.4,4.7c-0.2,0.5-0.5,0.9-0.8,1.3
		c-1.5,1.6-3.8,2.2-5.8,1.3L48,89c-1.5-0.7-3.3,0-4,1.5s0,3.3,1.5,4l20,8.9c1.5,0.7,3,1,4.5,1c3.1,0,6.1-1.3,8.2-3.7
		c0.7-0.8,1.3-1.8,1.8-2.8c1.4-3.1,1.2-6.7-0.4-9.6l17-7.4c1.2-0.5,2.6-0.5,3.8,0c1.2,0.5,2.2,1.5,2.7,2.7l0.5,1L90.5,90
		c-1.5,0.6-2.2,2.4-1.6,3.9s2.4,2.2,3.9,1.6l20.5-8.7c2.6-1.1,5.7-0.1,6.8,2.4c0.6,1.2,0.6,2.6,0.1,3.9s-1.5,2.3-2.7,2.8l-56.4,23.8
		c-4.5,1.9-9.7,1.8-14-0.4l-27.8-14c-1-0.5-2.2-0.4-3.1,0.2l-14,10c-1.3,1-1.7,2.8-0.7,4.2c1,1.3,2.8,1.7,4.2,0.7l12.5-8.9
		l26.2,13.2c3.2,1.6,6.6,2.3,10.1,2.3c3,0,6.1-0.6,9-1.8l56.4-23.8C122.7,100.2,124.8,98,125.9,95.2z"
            />
          </g>
        </svg>

        <p class="mt-8 md:mt-16 text-xl md:text-2xl">
          {{ $t("Covid-19.section9") }}
        </p>
      </div>
    </section>

    <section class="container mx-auto px-6 xl:px-12 mt-20 md:mt-32">
      <div class="">
        <p class="text-3xl md:text-5xl xl:text-6xl font-bold">
          {{ $t("Covid-19.section10") }}
        </p>
        <p class="mt-6 md:mt-10 xl:mt-16 text-xl md:text-2xl">
          {{ $t("Covid-19.section11") }}
        </p>
      </div>

      <div class="mt-24">
        <p class="text-3xl md:text-5xl xl:text-6xl font-bold">
          {{ $t("Covid-19.section12") }}
        </p>
        <p class="mt-6 md:mt-10 xl:mt-16 text-xl md:text-2xl">
          {{ $t("Covid-19.section13") }}
        </p>
        <p class="text-xl md:text-2xl">
          {{ $t("Covid-19.section14") }}
        </p>
        <p class="text-xl md:text-2xl">
          {{ $t("Covid-19.section15") }}
        </p>
      </div>

      <div class="mt-24">
        <p class="text-3xl md:text-5xl xl:text-6xl font-bold">
          {{ $t("Covid-19.section16") }}
        </p>
        <p class="mt-6 md:mt-10 xl:mt-16 text-xl md:text-2xl">
          <a target="_blank" class="underline" href="https://ncdc.org.ly/Ar/">
            {{ $t("Covid-19.section17") }}
          </a>
        </p>
        <p class="text-xl md:text-2xl">
          <a
            target="_blank"
            class="underline"
            href="https://www.who.int/ar/emergencies/diseases/novel-coronavirus-2019?fbclid=IwAR0zKkKo55PjZui9qt53BRa3jWekKSTaQHzfHZHqMX_3DoCD13ldBNFGAJs"
          >
            {{ $t("Covid-19.section18") }}
          </a>
        </p>
        <p class="text-xl md:text-2xl">
          <a
            target="_blank"
            class="underline"
            href="https://www.unicef.org/coronavirus/covid-19?fbclid=IwAR1Kkt9iSSUXDZoo6DK85xbF1tlrBK76ibVTTCxREwlBdSFIZPZmXGctBag"
          >
            {{ $t("Covid-19.section19") }}
          </a>
        </p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  created() {
    scrollTo(0, 0);
  },
  data() {
    return {
      art: false,
      Animation: false,
      Design: false,
      Photography: false,
    };
  },

  methods: {},
};
</script>

<style >
.background_image {
  background-image: url("../assets/images/COVID-19.jpg");
}
</style>